<template>
    <div :class="['panel', {'panel-full': full, 'panel-front': front}]">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'Panel',
        props: {
            full: Boolean,
            front: Boolean
        }
    }
</script>
