<template>
    <header :class="['panel-top-bar', `panel-top-bar-${variant}`, {[`panel-top-bar-${size}`]: size}]">
        <slot name="title">
            <img :src="logo" alt="E-Courier" v-if="withLogo" />
        </slot>
        <button type="button" class="close ml-auto" v-if="closable" @click="onClose">
            <fa icon="times" />
        </button>
    </header>
</template>

<script>
    import logo from '../../assets/images/logo-text.svg';

    export default {
        name: 'PanelTopBar',
        props: {
            variant: {
                type: String,
                default: 'primary'
            },
            size: String,
            withLogo: Boolean,
            closable: Boolean
        },
        data() {
            return {
                logo
            }
        },
        methods: {
            onClose() {
                this.$emit('close');
            }
        }
    }
0</script>
