<template>
    <div class="container-fluid px-0">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col mb-4">
                <panel full>
                    <panel-top-bar variant="primary-dark" with-logo />
                    <div class="panel-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aperiam blanditiis consequuntur, doloremque dolorum iusto magni provident saepe temporibus! Corporis deserunt enim, esse eum hic iusto laboriosam nisi repudiandae veniam?</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aperiam blanditiis consequuntur, doloremque dolorum iusto magni provident saepe temporibus! Corporis deserunt enim, esse eum hic iusto laboriosam nisi repudiandae veniam?</p>
                    </div>
                </panel>
            </div>
            <div class="col mb-4">
                <panel full>
                    <panel-top-bar variant="primary-dark" with-logo closable />
                    <div class="panel-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aperiam blanditiis consequuntur, doloremque dolorum iusto magni provident saepe temporibus! Corporis deserunt enim, esse eum hic iusto laboriosam nisi repudiandae veniam?</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aperiam blanditiis consequuntur, doloremque dolorum iusto magni provident saepe temporibus! Corporis deserunt enim, esse eum hic iusto laboriosam nisi repudiandae veniam?</p>
                    </div>
                </panel>
            </div>
            <div class="col mb-4">
                <panel full>
                    <panel-top-bar variant="primary" with-logo />
                    <div class="panel-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aperiam blanditiis consequuntur, doloremque dolorum iusto magni provident saepe temporibus! Corporis deserunt enim, esse eum hic iusto laboriosam nisi repudiandae veniam?</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aperiam blanditiis consequuntur, doloremque dolorum iusto magni provident saepe temporibus! Corporis deserunt enim, esse eum hic iusto laboriosam nisi repudiandae veniam?</p>
                    </div>
                </panel>
            </div>
            <div class="col mb-4">
                <panel full>
                    <panel-top-bar variant="primary" with-logo closable />
                    <div class="panel-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aperiam blanditiis consequuntur, doloremque dolorum iusto magni provident saepe temporibus! Corporis deserunt enim, esse eum hic iusto laboriosam nisi repudiandae veniam?</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aperiam blanditiis consequuntur, doloremque dolorum iusto magni provident saepe temporibus! Corporis deserunt enim, esse eum hic iusto laboriosam nisi repudiandae veniam?</p>
                    </div>
                </panel>
            </div>
            <div class="col mb-4">
                <panel full>
                    <panel-top-bar variant="primary" />
                    <div class="panel-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aperiam blanditiis consequuntur, doloremque dolorum iusto magni provident saepe temporibus! Corporis deserunt enim, esse eum hic iusto laboriosam nisi repudiandae veniam?</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aperiam blanditiis consequuntur, doloremque dolorum iusto magni provident saepe temporibus! Corporis deserunt enim, esse eum hic iusto laboriosam nisi repudiandae veniam?</p>
                    </div>
                    <div class="panel-footer panel-footer-box">
                        <button type="button" class="btn btn-default">Back</button>
                    </div>
                </panel>
            </div>
            <div class="col mb-4">
                <panel full>
                    <panel-top-bar variant="primary" />
                    <div class="panel-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aperiam blanditiis consequuntur, doloremque dolorum iusto magni provident saepe temporibus! Corporis deserunt enim, esse eum hic iusto laboriosam nisi repudiandae veniam?</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aperiam blanditiis consequuntur, doloremque dolorum iusto magni provident saepe temporibus! Corporis deserunt enim, esse eum hic iusto laboriosam nisi repudiandae veniam?</p>
                    </div>
                    <div class="panel-footer panel-footer-box">
                        <button type="button" class="btn btn-default">Back</button>
                        <button type="button" class="btn btn-primary">Submit</button>
                    </div>
                </panel>
            </div>
        </div>
    </div>
</template>

<script>
    import Panel from "../../components/Panel";
    import PanelTopBar from "../../components/PanelTopBar";

    export default {
        name: 'Panels',
        components: {
            PanelTopBar,
            Panel
        }
    }
</script>
